


import { Component, Vue } from "vue-property-decorator";
import { Login } from "@/shared/dtos/login";
import { UtilsString } from "@/utils/utils-string";

@Component({
  $_veeValidate: { validator: "new" },
})
export default class LoginFormulario extends Vue {
  public appTitle = process.env.VUE_APP_TITLE;

  public login!: Login;
  public hide: boolean = true;

  public mounted() {
    this.login = new Login();
    if (localStorage.getItem("login") !== null) {
      this.login = new Login(
        JSON.parse(UtilsString.ValueOf(localStorage.getItem("login")))
      );
      this.submit();
    }
    setTimeout(() => (this.hide = false), 1);
  }

  public submit() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        this.tryLogin();
      }
    });
  }

  public async tryLogin() {
    const authorizationService = await import(
      "@/shared/services/AuthorizationService"
    );
    authorizationService.authorizationService
      .login(this.login)
      .then(this.loginResult.bind(this));
  }

  private async loginResult(loginOk: boolean) {
    if (loginOk) {
      this.hide = true;
      localStorage.setItem("login", JSON.stringify(this.login));
      setTimeout(() => this.routeToMain(), 250);
    } else {
      const ssmMessageService = await import(
        "@/shared/services/message-service"
      );

      ssmMessageService.ssmMessageService.toast(
        "Usuario o Clave incorrectos",
        ssmMessageService.ssmMessageService.TypeError
      );
    }
  }

  private routeToMain() {
    this.$router.push("/");
  }
}
